@import "src/themes/mojito/styles/index.scss";
.qr-editor-skeleton {
  &__container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 744px) {
      flex-direction: row;
    }
  }

  &__first-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__qr-selector {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;

    > :nth-child(n + 7) {
      display: none;
    }

    @media (min-width: 728px) {
      > :nth-child(n + 7) {
        display: flex;
      }
    }

    @media (min-width: 1280px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__qr-button {
    border: 2px solid getvar($colors, "neutral", "400");
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    padding: 8px;
    padding-right: 12px;
    gap: 12px;
    align-items: center;

    &__icon {
      border-radius: 50%;
      min-width: 24px;
      min-height: 24px;
      background-color: getvar($colors, "neutral", "200");
    }
  }
  &__form {
    height: 100%;
  }

  &__accordion__container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__input__container {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__second-column {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 1024px) {
      align-items: center;
    }
  }
  &__render-qr {
    border: 5px solid getvar($colors, "neutral", "200");
    border-radius: 8px;
    width: 290px;
    height: 290px;
  }
  &__button {
    border: 1px solid getvar($colors, "neutral", "200");
    border-radius: 4px;
    height: 44px;
    width: 100%;
  }
}
